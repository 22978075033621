import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  catchError,
  delay,
  from,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import * as UserActions from '../../store/actions/user.actions';
import { User } from '../../shared/models/api/user';
import { Auth, signInWithEmailAndPassword } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router = inject(Router);
  store = inject(Store);
  firebaseAuth = inject(Auth);
  snackbar = inject(MatSnackBar);

  login(
    displayName: string,
    password: string,
    email: string,
    selectedUserId: string
  ): Observable<boolean> {
    const fireBaseAuthTenantId = environment.firebaseConfig.tenantId;
    this.firebaseAuth.tenantId = fireBaseAuthTenantId;

    if (email && password) {
      return from(
        signInWithEmailAndPassword(this.firebaseAuth, email, password)
      ).pipe(
        switchMap((userCredential) => {
          const user: User = {
            id: Number(selectedUserId),
            displayName: displayName || '',
            email: userCredential.user.email || '',
          };

          // save to store
          this.store.dispatch(UserActions.loginUserSuccess({ user }));

          return from(this.getValidToken()).pipe(
            map((token) => {
              if (token) {
                // Store the token in localStorage
                localStorage.setItem('paxi-next-jwt', token);
                return true; // Login successful
              } else {
                // Handle the case when there's no token
                return false; // Login failed
              }
            })
          );
        }),
        catchError((error) => {
          this.snackbar.open(error.message, 'Close', {
            duration: 3000,
          });
          return of(false); // Login failed
        })
      );
    } else {
      return of(false); // Invalid credentials
    }
  }

  private async getValidToken(): Promise<string | null> {
    const user = await this.firebaseAuth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken(true);
        return token;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
    return null;
  }

  logout(): void {
    this.firebaseAuth
      .signOut()
      .then(() => {
        this.router.navigate(['/home']);
      })
      .catch((error) => {});
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await this.firebaseAuth.currentUser;

      const isLoggedIn = !!user;

      return isLoggedIn;
    } catch (error) {
      return false;
    }
  }
}
